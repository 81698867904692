/**
 * Update date: 2024-11-29
 * Sales Trend
 */
import {
  ArgumentAxis,
  Chart,
  ConstantLine,
  Label,
  Legend,
  Series,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import useDictionary from 'hooks/useDictionary';
import { memo, useEffect, useRef, useState } from 'react';
import { formatNumber } from 'devextreme/localization';
import useFormat from 'hooks/useFormat';

const ChartDetailProduct = ({ chartData }) => {
  const heightChart = 320;
  const zIndexTooltip = 99999;
  const { t }: any = useDictionary({});
  const { QtyFormat } = useFormat();
  const chartStockRef: any = useRef(null);
  const paddingRef: any = useRef({
    stockWidth: 0,
    orderWidth: 0,
    discountWidth: 0,
  });
  const [rerenderChart, setRerenderChart] = useState(false);
  const diffWidth =
    paddingRef.current.stockWidth - paddingRef.current.orderWidth;
  let leftRightWidth = 0;
  if (diffWidth > 0) {
    leftRightWidth =
      paddingRef.current.stockWidth + paddingRef.current.discountWidth;
  } else {
    leftRightWidth =
      paddingRef.current.orderWidth + paddingRef.current.discountWidth;
  }

  useEffect(() => {
    if (chartData && !rerenderChart) {
      setTimeout(() => {
        setRerenderChart(true);
      }, 1200);
    }
  }, [chartData]);

  const onStockChartDrawn = e => {
    if (rerenderChart) return;
    const valueAxisElements = e?.element?.querySelectorAll?.('.dxc-val-axis');
    const boundingRect = valueAxisElements?.[0]?.getBoundingClientRect?.();
    const stockWidth = boundingRect?.width || 0;
    if (stockWidth > paddingRef.current.stockWidth) {
      paddingRef.current.stockWidth = stockWidth;
    }
    const boundingRectDiscount =
      valueAxisElements?.[1]?.getBoundingClientRect?.();
    const discountWidth = boundingRectDiscount?.width || 0;
    if (discountWidth > paddingRef.current.discountWidth) {
      paddingRef.current.discountWidth = discountWidth;
    }
  };

  const onOrderChartDrawn = e => {
    if (rerenderChart) return;
    const valueAxisElements = e?.element?.querySelectorAll?.('.dxc-val-axis');
    const boundingRect = valueAxisElements?.[0]?.getBoundingClientRect?.();
    const orderWidth = boundingRect?.width || 0;
    if (orderWidth > paddingRef.current.orderWidth) {
      paddingRef.current.orderWidth = orderWidth;
    }
  };
  return (
    <div>
      <div
        style={{
          width: '100%',
          height: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          fontWeight: 'bold',
        }}
      >
        <div style={{ width: '50%', textAlign: 'center', color: '#B54708' }}>
          {t('This Year')}
        </div>
        <div style={{ width: '50%', textAlign: 'center', color: '#B54708' }}>
          {t('Last Year')}
        </div>
      </div>
      <div
        style={{
          width: '100%',
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '100%',
            paddingLeft: diffWidth < 0 ? Math.abs(diffWidth) : 0,
          }}
          className="chart-padding-stock-custom"
        >
          <Chart
            ref={chartStockRef}
            dataSource={chartData?.combinedStock || []}
            title=""
            size={{ height: heightChart }}
            onDrawn={onStockChartDrawn}
            synchronizeMultiAxes={false}
          >
            <ArgumentAxis
              discreteAxisDivisionMode={'crossLabels'}
            ></ArgumentAxis>
            <Series
              type="bar"
              valueField="stockQty"
              argumentField="date"
              name={t('Stock')}
              axis="leftAxis"
              color="#53B1FD"
              barWidth={20}
            />
            <Series
              type="spline"
              valueField="saleQty"
              argumentField="date"
              name={t('Sales Quantity')}
              axis="leftAxis"
              color="#054F31"
              point={{ visible: false }}
            />
            <Series
              type="spline"
              valueField="discountRate"
              argumentField="date"
              name={t('Discount Rate(%)')}
              axis="rightAxis"
              color="#D06F2E"
              point={{ visible: false }}
              dashStyle="dash"
            />

            <ValueAxis
              name="leftAxis"
              position="left"
              title={t('Sales & Stock Quantity')}
            >
              <ConstantLine
                width={1}
                value={chartData?.safetyStockMinOrder?.safetyStock || 0}
                color="#12B76A"
                dashStyle="dash"
              >
                <Label text={t('Safety Stock')} horizontalAlignment="right" />
              </ConstantLine>
              <ConstantLine
                width={1}
                value={chartData?.safetyStockMinOrder?.minOrder || 0}
                color="#7F56D9"
                dashStyle="dash"
              >
                <Label text={t('MOQ')} horizontalAlignment="right" />
              </ConstantLine>
            </ValueAxis>
            <ValueAxis
              name="rightAxis"
              position="right"
              title={t('Discount Rate(%)')}
              visualRange={{ startValue: -10, endValue: 100 }}
              tickInterval={10}
              inverted={true}
              valueMarginsEnabled={true}
              grid={{ visible: false }}
            />
            <Tooltip
              enabled={true}
              shared={true}
              zIndex={zIndexTooltip}
              format={value => formatNumber(value, '#,##0.##')}
              customizeTooltip={pointInfo => {
                const safetyStock =
                  chartData?.safetyStockMinOrder?.safetyStock || 0;
                const minOrder = chartData?.safetyStockMinOrder?.minOrder || 0;
                const constantLineInfo = `${t('Safety Stock')}: ${formatNumber(
                  safetyStock,
                  QtyFormat,
                )}<br>${t('MOQ')}: ${formatNumber(minOrder, QtyFormat)}`;
                return {
                  text: `${pointInfo?.valueText || ''}<br>${constantLineInfo}`,
                };
              }}
            />
            <Legend visible={false} />
          </Chart>
        </div>
        <div
          style={{
            height: heightChart,
            position: 'absolute',
            left: `calc(50% - ${leftRightWidth / 2}px + ${
              diffWidth > 0
                ? paddingRef.current.stockWidth
                : paddingRef.current.orderWidth
            }px)`,
            bottom: 0,
          }}
          className="chart-line-middle-custom"
        >
          <div
            style={{
              height: 'calc(100% - 25px)',
              border: '1px dashed  rgb(241 200 197)',
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '100%',
            paddingLeft: diffWidth > 0 ? Math.abs(diffWidth) : 0,
            paddingRight: paddingRef.current.discountWidth,
          }}
          className="chart-padding-order-custom"
        >
          <Chart
            dataSource={chartData?.orderChart || []}
            title=""
            size={{ height: heightChart - 55 }}
            onDrawn={onOrderChartDrawn}
          >
            <ArgumentAxis
              visible={false}
              label={{ visible: false }}
              tick={{ visible: false }}
              grid={{ visible: false }}
              position="top"
              categories={chartData?.allDateEvent || []}
              discreteAxisDivisionMode={'crossLabels'}
            >
              <Label format="MM/dd" />
            </ArgumentAxis>
            <Series
              type="bar"
              valueField="orderQty"
              argumentField="date"
              name={t('Order Quantity')}
              axis="leftAxis"
              color="#039855"
              barWidth={20}
            />
            <Series
              type="spline"
              valueField="receiveQty"
              argumentField="date"
              name={t('Receive Quantity')}
              axis="leftAxis"
              color="#FFC107"
              point={{ visible: false }}
            />
            <ValueAxis
              name="leftAxis"
              position="left"
              title={t('Order & Receive Quantity')}
              inverted={true}
              valueMarginsEnabled={true}
            />
            <Tooltip
              enabled={true}
              shared={true}
              zIndex={zIndexTooltip}
              format={value => formatNumber(value, '#,##0')}
            />
            <Legend visible={false} />
          </Chart>
        </div>
        <div
          style={{
            height: heightChart - 55,
            width: 'calc(50% - 1px)',
            position: 'absolute',
            top: 0,
            left: `calc(50% - ${leftRightWidth / 2}px + ${
              diffWidth > 0
                ? paddingRef.current.stockWidth
                : paddingRef.current.orderWidth
            }px + 3px)`,
            backgroundColor: 'rgb(255, 255, 255)',
          }}
          className="chart-seria-custom"
        >
          <div
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '10px',
              paddingTop: 50,
              paddingLeft: 80,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ width: '40px', border: '3px dashed #12B76A' }} />
              <span>{t('Safety Stock')}</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ width: '40px', border: '3px dashed #7F56D9' }} />
              <span>{t('Minimum Order Quantity')}</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ width: '40px', border: '3px dashed #D06F2E' }} />
              <span>{t('Discount Rate')}</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ width: '40px', border: '3px solid #054F31' }} />
              <span>{t('Sales Quantity')}</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ width: '40px', border: '10px solid #53B1FD' }} />
              <span>{t('Stock')}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ width: '40px', border: '10px solid #039855' }} />
              <span>{t('Order Quantity')}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ width: '40px', border: '3px solid #FFC107' }} />
              <span>{t('Receive Quantity')}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ width: '40px', border: '10px solid #9472cf' }} />
              <span>{t('Event')}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          paddingTop: 10,
          paddingBottom: 40,
        }}
      >
        <div
          style={{
            width: `calc(100% - ${leftRightWidth}px)`,
          }}
          className="chart-padding-event-custom"
        >
          <div style={{ width: '100%' }}>{t('Event')}</div>
          <div
            style={{
              border: '1px solid rgb(179 173 189)',
              padding: '5px 0px',
            }}
          >
            <Chart
              dataSource={chartData?.combinedEvent || []}
              barGroupPadding={0.2}
              rotated={true}
              size={{
                height: chartData?.combinedEvent?.length * 35,
              }}
            >
              <ArgumentAxis
                title={''}
                inverted={true}
                valueMarginsEnabled={true}
                visible={false}
                label={{ visible: false }}
                tick={{ visible: false }}
                grid={{ visible: false }}
              />
              <ValueAxis
                title=""
                categories={chartData?.allDateEvent || []}
                visible={false}
                label={{ visible: false }}
                tick={{ visible: false }}
                grid={{ visible: false }}
              >
                <Label format="MM/dd" />
              </ValueAxis>
              <Series
                type="rangeBar"
                argumentField="eventNm"
                rangeValue1Field="startDate"
                rangeValue2Field="endDate"
                name={t('Event Date')}
                color="#9472cf"
                barWidth={28}
                label={{
                  visible: true,
                  format: 'fixedPoint',
                  horizontalAlignment: 'center',
                  verticalAlignment: 'middle',
                  customizeText: args => {
                    if (args.index === 1) {
                      return '';
                    } else {
                      return args.argument;
                    }
                  },
                  position: 'inside',
                  font: {
                    color: '#ffffff',
                    size: 11,
                    weight: 600,
                  },
                }}
              />
              <Tooltip
                enabled={true}
                shared={true}
                format={'yyyy-MM-dd'}
                zIndex={zIndexTooltip}
              />
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                visible={false}
              />
            </Chart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ChartDetailProduct);
