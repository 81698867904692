import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { Login } from 'app/pages/LoginPage';
// import RegisterProgram from 'app/pages/RegisterProgramPage';
import SignUp from 'app/pages/SignUp';
// import { SystemManagement } from 'app/pages/SystemManagementPage';
import Layout from 'app/components/Layout';
import PrivateRoute from './PrivateRoute';
import { ROLE } from 'constant/user';
import { prefixWebviewRouter, router, routerWebview } from 'constant/router';
// import { BookMark } from 'app/pages/BookMark';
import DynamicPage from 'app/pages/DynamicPage';
import { HomePage } from 'app/pages/HomePage';
import LayoutWebviewMobile from 'app/components/LayoutWebviewMobile';
import M2087_D from 'app/pages/M2087_D';

export function RouterApp() {
  const currentPath = window?.location?.pathname || '';
  const isWebViewRoute = currentPath?.startsWith?.(prefixWebviewRouter);

  return (
    <Router>
      {isWebViewRoute ? (
        <LayoutWebviewMobile>
          <Switch>
            <Route
              exact
              path={routerWebview.m2087_D_webview}
              component={M2087_D}
            />
          </Switch>
        </LayoutWebviewMobile>
      ) : (
        <Layout>
          <Switch>
            <Route exact path={router.login} component={Login} />
            <Route exact path={router.sign_up} component={SignUp} />
            {/* <PrivateRoute
            exact
            path={router.search}
            component={DynamicPage}
            roles={[ROLE.admin, ROLE.customer]}
          />
          <PrivateRoute
            exact
            path={router.history}
            component={DynamicPage}
            roles={[ROLE.admin, ROLE.customer]}
          />
          <PrivateRoute
            exact
            path={router.book_mark}
            component={DynamicPage}
            roles={[ROLE.admin, ROLE.customer]}
          /> */}
            <PrivateRoute
              exact
              path={router.notification}
              component={DynamicPage}
              roles={[ROLE.admin, ROLE.customer]}
            />
            <PrivateRoute
              exact
              path={'/:programId1/:programId2/:programId3'}
              component={DynamicPage}
              roles={[ROLE.admin, ROLE.customer]}
            />
            {/* <PrivateRoute
            exact
            path={router.system_management}
            component={SystemManagement}
            roles={[ROLE.admin, ROLE.customer]}
          />
          <PrivateRoute
            exact
            path={router.register_program}
            component={RegisterProgram}
            roles={[ROLE.admin, ROLE.customer]}
          />
          <PrivateRoute
            exact
            path={router.book_mark}
            component={BookMark}
            roles={[ROLE.admin, ROLE.customer]}
          />
          <PrivateRoute
            exact
            path={router.history}
            component={History}
            roles={[ROLE.admin, ROLE.customer]}
          /> */}
            <PrivateRoute
              exact
              path={router.home}
              component={HomePage}
              roles={[ROLE.admin, ROLE.customer]}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      )}
    </Router>
  );
}
