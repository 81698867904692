import LoadPanel from 'app/components/LoadPanel';
import WrapContent from 'app/components/WrapContent/WrapContent';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import useDictionary from 'hooks/useDictionary';
import React, { useContext } from 'react';
import '../HomePage/styles.scss';
import TabReorderPoint from './TabReorderPoint';
import { LayoutContext } from 'app/components/Layout';
import { programContant } from 'constant/router';

const AlarmNotice = React.lazy(() => import('../AlarmNotice'));
const M2087DWeb = React.lazy(() => import('../M2087_D_Web'));

export function HomePage() {
  const { t } = useDictionary({});
  const layOutContext: any = useContext(LayoutContext);

  return (
    <>
      <WrapContent>
        <React.Suspense fallback={<LoadPanel visible={true} />}>
          <LoadPanel visible={false} />
          <div
            className="title-page"
            style={{ marginTop: 0, lineHeight: '25px' }}
          >
            {t('Today Report')}
          </div>
          <div className="home-page-content">
            <TabPanel
              loop
              animationEnabled
              swipeEnabled={false}
              deferRendering={true}
              repaintChangesOnly
              showNavButtons
              scrollingEnabled
              scrollByContent
              defaultSelectedIndex={0}
              className="home-tab-panel-custom"
            >
              <Item title={t('Notification')} key={`tab-notification`}>
                <AlarmNotice />
              </Item>
              <Item
                title={t('Reorder Point (ROP) For Order')}
                key={`tab-reorder-point`}
              >
                <TabReorderPoint />
              </Item>
              <Item
                title={t('Sales Performance by Store')}
                key={`tab-sales-performance-by-store`}
                visible={layOutContext?.fullMenu?.some?.(
                  o => o.programId === programContant.m2087_D,
                )}
              >
                <M2087DWeb />
              </Item>
            </TabPanel>
          </div>
        </React.Suspense>
      </WrapContent>
    </>
  );
}
